<!--
 * @Author: Musa Tabitay
 * @Date: 2022-01-02 00:49:24
 * @LastEditTime: 2022-01-06 23:16:05
-->

<template>
  <div class="my-container">
    <van-nav-bar
      title="جەكەلىك"
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
      </div>
    </van-nav-bar>

    <div class="header not-login">
      <div class="login-btn">
        <img class="mobile-img" :src="userInfo.avatar_url" />
        <span class="nick_name">{{ userInfo.nick_name }}</span>
        <span class="uid">UID: {{ userInfo.id }}</span>
      </div>
    </div>

    <!-- 宫格导航 -->
    <!-- <van-grid class="grid-nav" :column-num="3" clickable>
      <van-grid-item to="/mylist?type=shoucang">
        <van-icon slot="icon" name="star-o" color="#eb5253" />
        <span slot="text" class="text">ساقتاعانىم</span>
      </van-grid-item>
      <van-grid-item to="/mylist?type=buy">
        <van-icon slot="icon" name="shopping-cart-o" color="#ff9d1d" />
        <span slot="text" class="text">ساتىپ العانىم</span>
      </van-grid-item>
       <van-grid-item to="/vip">
        <van-icon slot="icon" name="vip-card-o" color="#ff9d1d" />
        <span slot="text" class="text">VIP ەرەكشە مۇشە</span>
      </van-grid-item>
    </van-grid> -->

    <div class="rtl">
      <!-- <van-cell
        title="باعدارلاما ءجايلى"
        is-link
        arrow-direction="left"
        to="/about"
      />
      <van-cell title="كومەك سۇراۋ" is-link arrow-direction="left" to="/help" /> -->
      <van-cell
        title="ساقتاعاندارىم"
        is-link
        arrow-direction="left"
        to="/mylist?type=shoucang"
      />
      <van-cell
        title="ساتىپ العانىم"
        is-link
        arrow-direction="left"
        to="/mylist?type=buy"
      />
      <van-cell
        title="VIP ەرەكشە مۇشە"
        is-link
        arrow-direction="left"
        to="/vip"
      />
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/user'

export default {
  name: 'MyIndex',
  data () {
    return {
      userInfo: {} // 用户信息
    }
  },
  mounted () {
    document.title = 'جەكەلىك'
    this._getUserInfo()
  },
  methods: {
    async _getUserInfo () {
      try {
        const openId = this.$cookies.get('openId')
        const { data } = await getUserInfo(openId)
        this.userInfo = data
      } catch (error) {
        console.log(error)
      }
    },

    async logout () {
      try {
        await this.$dialog.confirm({
          title: '确认退出吗？',
          confirmButtonColor: '#3296fa'
        })

        this.$store.commit('setUser', null)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.my-container {
  padding-top: 92px;
  .header {
    height: 364px;
    background: url("~@/assets/banner.png");
    background-size: cover;
  }

  .not-login {
    display: flex;
    justify-content: center;
    align-items: center;
    .login-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      .mobile-img {
        width: 132px;
        border-radius: 50%;
      }
      .nick_name {
        font-size: 32px;
        margin-top: 40px;
      }
      .uid {
        font-size: 28px;
      }
    }
  }

  .user-info {
    .base-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 234px;
      padding: 78px 30px 18px;
      box-sizing: border-box;

      .left {
        display: flex;
        align-items: center;

        .van-image {
          width: 132px;
          height: 132px;
          border: 4px solid #fff;
        }

        span {
          font-size: 30px;
          color: #fff;
          margin-left: 22px;
        }
      }
    }

    .data-stats {
      display: flex;
      height: 130px;
      .data-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        color: #fff;

        .count {
          font-size: 36px;
        }

        .text {
          font-size: 23px;
          margin-top: 4px;
        }
      }
    }
  }

  .grid-nav {
    margin-bottom: 10px;

    i.toutiao {
      font-size: 45px;
    }

    .toutiao-shoucang {
      color: #eb5253;
    }

    .toutiao-lishi {
      color: #ff9d1d;
    }

    span.text {
      margin-top: 13px;
      font-size: 28px;
    }
  }

  .logout {
    color: #d86262;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
